






























































































.card-wrapper {
  max-width: 1024px;
}

.preview-container {
  display: flex;
  justify-content: center;
}

.preview-actions {
  margin-top: 30px;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  padding-top: 30px;
  .btn {
    width: 150px;
  }
}
